import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '@tuacar-workspace/core';

import { provideAnimations } from '@angular/platform-browser/animations';
import { it_IT, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt, 'it');

export const appConfig: ApplicationConfig = {
  providers: [
    provideNzI18n(it_IT),
    provideHttpClient(),
    provideRouter(appRoutes),
    provideAnimations(),
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink): ApolloClientOptions<unknown> => ({
        // link: ApolloLink.from([
        //   httpLink.create({ uri: environment.graphqlUri }),
        // ]),
        link: ApolloLink.split(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (operation) => (operation.getContext() as any).apiType === 'public', // boolean check
          httpLink.create({ uri: environment.graphqlUriPublic }), // if true
          httpLink.create({ uri: environment.graphqlUri }), // if fa
        ),
        cache: new InMemoryCache(),
      }),
      deps: [HttpLink],
    },
    Apollo,
  ],
};
