import { map, Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client';
import { GET_CARS } from './queries.graphql';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CarListResponse } from '../models/slideshow.model';

@Injectable({ providedIn: 'root' })
export class CarService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  find(agencies?: string[]): Observable<CarListResponse> {
    return this.apollo
      .query({
        query: GET_CARS,
        variables: {
          tenantCode: 'tenant_001',
          agenziaCodes: agencies,
        },
        context: {
          apiType: 'public',
        },
        returnPartialData: false,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((result: ApolloQueryResult<any>) => {
          const resp = {
            data: result.data.carsPublic.nodes,
            totalCount: result.data.carsPublic.totalCount,
          } as CarListResponse;

          return resp;
        }),
      );
  }
}
