<!-- <img [src]="currentImage$$()" alt="Slideshow image" /> -->

<div class="slideshow-grid">
  <div
    class="slideshow-item"
    *ngFor="let car of getVisibleImages(); let i = index"
  >
    <img [src]="car.pictureSlideshowUrl" alt="Car {{ i + 1 }}" />
  </div>
</div>
