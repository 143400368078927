import { gql } from 'apollo-angular';

export const GET_CARS = gql`
  query carsPublic($tenantCode: String!, $agenziaCodes: [String]) {
    carsPublic(
      tenantCode: $tenantCode
      agenziaCodes: $agenziaCodes
      limit: 999
      sortField: "insertedDate"
      sortDirection: DESCENDING
      online: true
      status: 1
    ) {
      totalCount
      nodes {
        id
        insertedDate
        pictureSlideshowUrl
      }
    }
  }
`;
