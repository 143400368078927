import { Route } from '@angular/router';
import { CarValuationComponent } from './car-valuation/car-valuation.component';
import { CarValuationSuccessComponent } from './car-valuation/car-valuation-success.component';
import { SlideshowComponent } from './slideshow/slideshow.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'car-valuation',
    pathMatch: 'full',
  },
  {
    path: 'car-valuation',
    component: CarValuationComponent,
  },
  {
    path: 'car-valuation/success',
    component: CarValuationSuccessComponent,
  },
  {
    path: 'slideshow',
    component: SlideshowComponent,
  },
];
