import { Component, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarService } from './services/car.service';
import { CarListResponse } from './models/slideshow.model';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-slideshow',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './slideshow.component.html',
  styleUrl: './slideshow.component.scss',
})
export class SlideshowComponent implements OnInit {
  private carService = inject(CarService);
  private route = inject(ActivatedRoute);

  cars$$ = signal<CarListResponse>({ data: [], totalCount: 0 });

  currentIndex = 0; // Indice del primo elemento visibile

  constructor() {
    const agencies = this.route.snapshot.queryParamMap
      .get('agencies')
      ?.split(',');

    this.carService.find(agencies).subscribe((cars) => {
      this.cars$$.set(cars);
    });
  }

  ngOnInit(): void {
    interval(30000).subscribe(() => this.nextImage()); // Cambia immagine ogni 5 sec

    interval(300000).subscribe(() => {
      const agencies = this.route.snapshot.queryParamMap
        .get('agencies')
        ?.split(',');

      this.carService.find(agencies).subscribe((cars) => {
        this.cars$$.set(cars);
      });
    }); // Aggiorna le immagini ogni 5 min
  }

  /** 🔁 Restituisce 4 immagini in ordine inverso (basso-sx → basso-dx → alto-dx → alto-sx) */
  getVisibleImages() {
    const data = this.cars$$().data;
    if (data.length === 0) return [];

    // Scorrimento dal basso verso l'alto: basso-sinistra, basso-destra, alto-destra, alto-sinistra
    return [
      data[(this.currentIndex + 3) % data.length], // Seconda immagine in basso-destra
      data[(this.currentIndex + 2) % data.length], // Prima immagine in basso-sinistra
      data[(this.currentIndex + 1) % data.length], // Terza immagine in alto-destra
      data[(this.currentIndex + 0) % data.length], // Quarta immagine in alto-sinistra
    ];
  }

  /** ▶️ Passa alla prossima immagine in ordine ciclico */
  nextImage() {
    if (this.cars$$().data.length > 0) {
      this.currentIndex = (this.currentIndex + 1) % this.cars$$().data.length; // Aumenta l'indice ciclicamente
    }
  }
}
